import { $EventBus } from '@/eventBus'

export const fatturaMixin = {
  methods: {
    hello: function () {
      console.log('hello from mixin!')
    },
    getTipipag () {
      return new Promise((resolve, reject) => {
        this.$http({
          method: 'get',
          url: '/fattureonline/modpag/' + this.fattura.pagamento_id_esterno })
          .then(response => {
            resolve(response.data)
          })
          .catch(err => {
            reject(err)
            $EventBus.$emit({ type: 'error', text: 'Errore modalita pagamento' })
          })
      })
    },
    creaDatiEsenzioni () {
      let esenzioni = {}
      let ive = {}
      let esenzioniArr = []
      let iveArr = []

      for (let v of this.voci) {
        let ali = this.iva.find((el) => {
          return el.cod_iva === v.cod_iva
        })
        if (ali.valore_iva === '0') {
          if (esenzioni[ali.cod_iva]) {
            esenzioni[ali.cod_iva].imponibile += v.imponibile
          } else {
            esenzioni[ali.cod_iva] = { descr: ali.descrizione_iva, imponibile: v.imponibile }
          }
        }
        if (ive[ali.cod_iva]) {
          ive[ali.cod_iva].imponibile += v.imponibile
        } else {
          ive[ali.cod_iva] = { descr: ali.label, imponibile: v.imponibile, aliquota: ali.valore_iva }
        }
      }

      // trasformo in un array
      for (let v of Object.keys(esenzioni)) {
        if (esenzioni[v].imponibile > 0) esenzioniArr.push(esenzioni[v])
      }
      for (let v of Object.keys(ive)) {
        if (ive[v].imponibile > 0) iveArr.push(ive[v])
      }

      return { esenzioni: esenzioniArr, ive: iveArr }
    }
  }
}
